import React from "react"
import { splitPrice } from '../../../utils/misc';
import theme from '../../../config/theme';

const ProductPrice = (props) => {
    const price = splitPrice(props.product.totalPrice);
    const oldPrice = props.product.totalOldPrice !== props.product.totalPrice && props.product.totalOldPrice ? splitPrice(props.product.totalOldPrice) : null;

    return (
        <div className={`product-price ${props.className}`}>
            <style jsx>{`
                .product-price {
                    height: 50px;
                    background-color: ${theme.colors.base.white};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                }

                .price {
                    display: flex;
                    font-size: 22px;
                    font-weight: 700;
                    color: ${theme.colors.base.red};
                }

                .price.old-price {
                    font-size: 14px;
                    font-weight: 400;
                    color: ${theme.colors.base.black};
                    margin-right: 5px;
                }

                .price.old-price .integer-price, 
                .price.old-price .fractional-price {
                    text-decoration: line-through;
                }

                .price .integer-price {
                    font-size: 1em;
                }

                .price.old-price .integer-price {
                    font-size: 1em;
                }

                .price .fractional-price {
                    font-size: 0.6em;
                    padding-bottom: 4px;
                }

                .price .currency {
                    font-size: 1em;
                    margin-left: 5px;
                }


                .price.promo-price {
                    margin-top: 0px;
                    color: ${theme.colors.base.red};
                }

                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .price {
                        font-size: 18px;
                    }

                    .price.old-price {
                        font-size: 16px;
                    }

                }
            `}</style>

            {oldPrice ?
                <div className={`price old-price`}>
                    <span className="integer-price">
                        {oldPrice[0]}
                    </span>
                    <span className="fractional-price">
                        .{oldPrice[1]}
                    </span>
                    <span className="currency">
                        Lei
                    </span>
                </div> : null}

            <div className={`price ${oldPrice ? 'promo-price' : ''}`}>
                <span className="integer-price">
                    {price[0]}
                </span>
                <span className="fractional-price">
                    .{price[1]}
                </span>
                <span className="currency">
                    {props.product.price === 1 ? 'Leu' : 'Lei'}
                </span>
            </div>
        </div>
    )
}

export default ProductPrice;
