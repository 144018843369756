import React from "react"
import AddToCartButton from './AddToCartButton';
import UnavailableButton from './UnavailableButton';

const ProductAddToCart = (props) => {
    return (
        <div className={`add-to-cart ${props.canAdd ? '' : 'disabled'}`}>
            <style jsx>{`
                .add-to-cart {
                    margin-top: 0px;
                    width: 100%;
                    display: flex; 
                    justify-content: center;
                }
                
            `}</style>
            {props.forceAdd || 
                (props.canAdd && (['available', 'supplier'].indexOf(props.product.availability) !== -1 
                || props.product.quantity > 0 
                || props.product.preorderQuantity > 0)) ? 
                <AddToCartButton  
                    inCart={props.inCart}
                    loading={props.loading} onClick={props.onClick} big={props.big} disabled={props.canAdd ? false : true} /> :
                <UnavailableButton disabled/>}
        </div>
    )
}

export default ProductAddToCart;