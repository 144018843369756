import metas from '../../config/meta.json';
import Head from 'next/head';
import { useRouter } from 'next/router';

const processTemplate = (template, vars) => {
    if(Object.keys(vars).length === 0) {
        return template;
    }

    for(let i in vars) {
        const regex = new RegExp(`{${i}}`, 'gi');
        template = (template ||'').replace(regex, vars[i]);
    }

    return template;
}

const MetaElement = (props) => {
    const pageId = props.pageId;
    const variables = props.variables || {};

    if(!metas[pageId]) {
        metas[pageId] = {}
    }

    return (
        <Head>
            <title>{processTemplate(metas[pageId].title, variables)}</title>
            <meta name="description" content={processTemplate(metas[pageId].description, variables)}/>
            <meta property="og:title" content={processTemplate(metas[pageId].ogTitle || metas[pageId].title, variables)}/>
            <meta property="og:description" content={processTemplate(metas[pageId].ogDescription || metas[pageId].description, variables)}/>
            <meta property="og:image" content={processTemplate(metas[pageId].ogImage || '', variables)}/>
            <meta property="og:type" content="website" />
        </Head>
    )
}

export default MetaElement;