import React, { useEffect, useState } from "react"
import Icon from "react-icons-kit"
import { shoppingBasket } from "react-icons-kit/fa";
import theme from "../../../config/theme"

import {u1F630} from 'react-icons-kit/noto_emoji_regular/u1F630'
import { spinner } from "react-icons-kit/fa";
import { GlobalContext } from "../../../contexts/GlobalContext";


const AddToCartButton = (props) => {

    let noStockTimeout = null;

    const [noStock, setNoStock] = useState(false);
    const cartIcon= props.loading ? spinner : shoppingBasket;

    const onClick = (e) => {
        if (props.disabled) {
            setNoStock(true);
            clearTimeout(noStockTimeout);
            noStockTimeout = setTimeout(() => {
                setNoStock(false);
            }, 700)
        } else {
            if(props.loading) return;
            
            props.onClick(e);
        }
    }

    return (
        <button onClick={onClick} className={`${props.big ? 'big' : ''} ${props.disabled && noStock ? 'disabled' : ''}`}>
            <style jsx>{`
            button {
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
                border: 0;
                color: ${theme.colors.base.white};
                background: linear-gradient(to right, ${theme.colors.gradients.redA} 10%,${theme.colors.gradients.redB});
                width: 100%;
                font-weight: bold;
                text-transform: uppercase;
                box-sizing: border-box;
                height: 42px;
                font-size: 1.2em;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                border-radius: 4px;
                overflow: hidden;
                transition: background 0.1s ease-out;
                opacity: 0.9;
            }

            button:not(.disabled):hover {
                box-shadow:0 0 10px #01A59B;
            }


            button:not(.disabled):active {
                outline: none;
                filter: saturate(1.4);
            }

            button:focus {
                outline: none;
            }


            .cart-icon {
                background: linear-gradient(to right,${theme.colors.base.black} 10%,${theme.colors.base.base});
                height: 42px;
                width: 32px;
                max-width: 42px;
                padding: 0px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                min-width: 0%;
                max-width: 100%;
            }


            .text {
                transition-delay: 0.02s;

                flex-grow: 1;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                min-width: 0%;
                max-width: 100%;
                transition: min-width 0.1s ease-in;
                white-space: nowrap;

            }

            .cart-icon.active {
                background: ${theme.colors.gradients.blueA};
                font-size: 1.4rem;
            }

            @media only screen and (max-width: ${theme.responsive.m}px) {
                button {
                    min-width: 100px;
                    font-size: 0.9rem;
                    padding: 0;
                }

                .cart-icon {
                    width: 32px;
                }
            }

            @media only screen and (max-width: ${theme.responsive.m}px) {
                ${props.big ? 'font-size: 1rem;' : 'font-size: 0.8rem;'}
            }

            .wide {
                min-width: 100%;
                border-radius: 0;
            }

            .narrow {
                max-width: 0%;
            }

            .loading-icon {
                -webkit-animation:spin 2s linear infinite;
                -moz-animation:spin 2s linear infinite;
                animation:spin 2s linear infinite;
            }

            @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
            @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
            @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

        `}</style>
                <div className={`cart-icon ${props.disabled && noStock ? 'wide' : ''} ${props.inCart && !noStock ? 'active' : ''}`}>
                    {props.disabled && noStock ?
                        <div className="text"><Icon size={32} icon={u1F630}></Icon> &nbsp; Stoc insuficient</div> :
                        <span className={` ${props.loading ? 'loading-icon' : ''}`}>
                            {props.inCart ?
                                props.inCart
                            : <Icon size={30} icon={cartIcon} /> }


                        </span>}
                </div>
                <div className={`text ${props.disabled && noStock ? 'narrow' : ''}`} >
                    {props.disabled && noStock ? '' :
                        props.text || 'Adauga in Cos'}
                </div>
        </button>
    )
}

export default AddToCartButton;
