import React from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";

import ProductAddToCart from "./ProductAddToCart";
import ProductPrice from "./ProductPrice";
import ProductThumbnail from "./ProductThumbnail";
import ProductTitle from "./ProductTitle";

import theme from "../../../config/theme";
import Link from "../Base/Link";
import OneClickBuyButton from "./OneClickBuyButton";
import ProductTag from "./ProductTag";

const Product = (props) => {
  //Adjustment for typesense's stupid handling of ids
  props.product.id = parseInt(props.product.id);

  return (
    <div className={`product`}>
      <style jsx>
        {`
          .product {
            display: flex;
            width: 100%;
            min-width: 100%;
            padding: 15px;
            margin: 5px;
            box-sizing: border-box;
            flex-direction: column;
            cursor: pointer;

            max-width: 300px;
            border-radius: 2px;
            transition: all 0.1s ease-in;
            border-radius: 10px;
          }

          .product-element {
            background-color: ${theme.colors.base.white};
            display: flex;
            flex-direction: column;
          }

          .product:first-of-type {
            margin-left: 0px;
          }

          .product:last-of-type {
            margin-right: 0px;
          }

          :global(.out-of-stock) {
            filter: grayscale() brightness(99%);
          }

          .product-data {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }

          .thumbnail {
            width: 100%;
            min-width: 150px;
            height: 220px;
            border-bottom: 0;
            box-sizing: border-box;
            position: relative;
            transition: all 0.1s;

            display: flex;
            justify-content: center;
          }

          @media only screen and (max-width: ${theme.responsive.m}px) {
            .product-element {
              align-items: center;
            }
            .product {
              padding: 4px;
              margin: 0;
              align-items: center;
            }

            .thumbnail {
              min-width: ${props.variant === "showcase" ? "200px" : "130px"};
              height: ${props.variant === "showcase" ? "160px" : "160px"};
            }

            .product-data {
              ${props.variant === "showcase" ? "height: 265px;" : ""}
            }
          }

          @media only screen and (min-width: ${theme.responsive.s}px) {
            .product-data {
              margin-left: ${props.variant === "showcase" ? "10px" : "0"};
            }
          }

          @media only screen and (max-width: ${theme.responsive.s}px) {
            .product-element {
              flex-direction: column;
            }

            .product-data {
              height: auto;
            }
          }

          .in-cart-combo {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            opacity: 0.9;
            font-weight: 900;
          }

          .in-cart-number {
            font-size: 14px;
            margin-right: 3px;
            text-align: center;
          }

          .in-cart-icon {
            margin-top: -5px;
            opacity: 0.8;
          }
        `}
      </style>
      <Link
        className={`product-element 
                ${
                  props.product.quantity > 0
                    ? ""
                    : props.product.preorderQuantity > 0
                    ? " preorder"
                    : " out-of-stock"
                }`}
        as={`/produs/${props.product.slug}`}
        href={`/produs/[slug]`}
      >
        <div className="thumbnail">
          <ProductThumbnail priority={props.priority} product={props.product} />
          {props.product.percentageDiscount > 0 ? (
            <ProductTag left={true} variant="price" round={true}>
              -{props.product.percentageDiscount}%
            </ProductTag>
          ) : null}

          {props.product.quantity === 0 ? (
            props.product.preorderQuantity > 0 ? (
              <ProductTag right={true} variant="preorder">
                Precomanda
              </ProductTag>
            ) : null
          ) : null}
        </div>

        <div className="product-data">
          <ProductTitle variant={props.variant}>
            {props.product.title}
          </ProductTitle>
          <ProductPrice product={props.product} />
        </div>
      </Link>

      <GlobalContext.Consumer>
        {(GlobalContext) => {
          const isProductInCart = GlobalContext.isInCart(
            props.product.id,
            "product"
          );

          const showOneClickBuy =
            props.product.service &&
            props.product.oneClickBuy &&
            GlobalContext.user &&
            !GlobalContext.user.disableOneClickBuy &&
            props.product.quantity > 0;

          return props.showAddToCart !== false ? (
            showOneClickBuy ? (
              <OneClickBuyButton
                status={GlobalContext.globalOneClickBuyStatus(props.product.id)}
                loading={GlobalContext.globalOneClickBuyLoading(
                  props.product.id,
                  props.type === "single" ? "card" : "product"
                )}
                text="Rezerva Direct"
                onClick={() => GlobalContext.oneClickBuy(props.product)}
              />
            ) : (
              <ProductAddToCart
                inCart={isProductInCart}
                loading={GlobalContext.globalCartLoading(props.product.id)}
                forceAdd={
                  props.product.quantity > 0 ||
                  props.product.preorderQuantity > 0
                }
                product={props.product}
                canAdd={
                  props.product.quantity >
                    GlobalContext.isInCart(props.product.id) ||
                  (props.product.quantity === 0 &&
                    props.product.preorderQuantity >
                      GlobalContext.isInCart(props.product.id))
                }
                onClick={() => {
                  if (
                    props.product.quantity >
                      GlobalContext.isInCart(props.product.id) ||
                    props.product.preorderQuantity >
                      GlobalContext.isInCart(props.product.id)
                  ) {
                    GlobalContext.addToCart(props.product);
                  }
                }}
              />
            )
          ) : null;
        }}
      </GlobalContext.Consumer>
    </div>
  );
};

export default Product;
