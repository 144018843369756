import React from "react"
import theme from "../../../config/theme";

const ProductTitle = (props) => {
    return (
        <div className="product-title" title={props.children}>
            <style jsx>{`
                .product-title {             
                    min-height: 32px;                  
                    margin-top: 5px;
                    
                    text-align: center;

                    font-size: 13px;
                    font-weight: 600;

                    color: ${theme.colors.base.base};
                    background-color: ${theme.colors.base.white};

                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                }
            `}</style>
            {props.children}
        </div>
    )
}

export default ProductTitle;