import React from "react";
import Icon from "react-icons-kit";
import { spinner } from "react-icons-kit/fa";
import { check, xCircle } from "react-icons-kit/feather";
import { ic_touch_app } from "react-icons-kit/md/ic_touch_app";
import theme from "../../../config/theme";

const OneClickBuyButton = (props) => {
  const onClick = (e) => {
    props.onClick(e);
  };

  return (
    <button
      onClick={() =>
        props.loading || props.status === "success" || props.status === "failed"
          ? null
          : onClick()
      }
      className={`${props.status}`}
    >
      <style jsx>{`
        button {
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
          border: 0;
          color: ${theme.colors.base.white};
          background: linear-gradient(
            to right,
            ${theme.colors.base.black} 10%,
            ${theme.colors.base.base}
          );
          width: 100%;
          font-weight: bold;
          text-transform: uppercase;
          box-sizing: border-box;
          height: 42px;
          font-size: 1.2em;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          border-radius: 4px;
          overflow: hidden;
          transition: background 0.1s ease-out;
          opacity: 0.9;
        }

        button.success,
        button.failed {
          cursor: auto;
        }

        button.not_actioned:hover {
          box-shadow: 0 0 10px #01a59b;
        }

        button.not_actioned:active {
          outline: none;
          filter: saturate(1.4);
        }

        button:focus {
          outline: none;
        }

        .text {
          transition-delay: 0.02s;

          flex-grow: 1;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;

          min-width: 0%;
          max-width: 100%;
          transition: min-width 0.1s ease-in;
          white-space: nowrap;
        }

        @media only screen and (max-width: ${theme.responsive.m}px) {
          button {
            min-width: 100px;
            width: 100%;
            font-size: 0.9rem;
            padding: 0;
          }

          .cart-icon {
            width: 32px;
          }
        }

        @media only screen and (max-width: ${theme.responsive.m}px) {
          ${props.big ? "font-size: 1rem;" : "font-size: 0.8rem;"}
        }

        .wide {
          min-width: 100%;
          border-radius: 0;
        }

        .narrow {
          max-width: 0%;
        }

        .loading-icon {
          -webkit-animation: spin 2s linear infinite;
          -moz-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
        }

        @-moz-keyframes spin {
          100% {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        .target-icon {
          margin-right: 5px;
        }

        .success {
          background: linear-gradient(
            to right,
            ${theme.colors.gradients.greenA} 10%,
            ${theme.colors.gradients.greenB}
          );
        }

        .failed {
          background: linear-gradient(
            to right,
            ${theme.colors.gradients.redA} 10%,
            ${theme.colors.gradients.redB}
          );
        }
      `}</style>
      {props.status && props.status === "not_actioned" ? (
        <div className={`text`}>
          <span
            className={` ${
              props.loading ? "loading-icon target-icon" : "target-icon"
            }`}
          >
            <Icon size={30} icon={!props.loading ? ic_touch_app : spinner} />
          </span>
          {props.text || "Cumpara Direct"}
        </div>
      ) : props.status && props.status === "success" ? (
        <div className={`text`}>
          <span
            className={` ${
              props.loading ? "loading-icon target-icon" : "target-icon"
            }`}
          >
            <Icon size={30} icon={check} />
          </span>
          Rezervat!
        </div>
      ) : props.status && props.status === "failed" ? (
        <div className={`text`}>
          <span
            className={` ${
              props.loading ? "loading-icon target-icon" : "target-icon"
            }`}
          >
            <Icon size={30} icon={xCircle} />
          </span>
          Eroare :(
        </div>
      ) : null}
    </button>
  );
};

export default OneClickBuyButton;
