import React from "react"
import theme from "../../../config/theme"

const UnavailableButton = (props) => (
    <div>
        <style jsx>{`
            div {
                border: 0;
                color: ${theme.colors.base.darkGray};
                font-weight: bold;
                box-sizing: border-box;
                height: 42px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center
                padding: 0;
                text-transform: uppercase;
                padding: 0 10px;

            }

        `}</style>
            Indisponibil
    </div>
)

export default UnavailableButton;